<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
      <p class="lead">
        Unblock Me Registration
      </p>

      <p>
        If you wish to receive emails from our sales department about Ma Labs’ product lines, promotions, and events, please complete the "Unblock Me" registration. Simply submit the email address below, and a verification email will be sent to you. Please verify the email address to complete the process.
      </p>
      
      <div v-if="error" class="alert alert-danger">
        <p class="fw-bold">{{error}}</p>
        <p>You cannot unblock this email address/account until you successfully
          register your account in Ma Labs "Block Me" Registry.
        </p>
      </div>

      <div v-if="message" class="alert alert-success">
        <div class="lead"><p>Verify your email address</p></div>
        <p class="fw-bold">{{message}}</p>
        <p>If you do not receive the verification email, please check your
          "Spam" or "Bulk Mail" folder or contact <a href="mailto:webmaster@malabs.com">webmaster@malabs.com</a>
          for assistance.
        </p>
      </div>

      <form class="row g-3">
        <div class="col-md-6" :class="{'alert alert-danger': data.email.error}">
          <label class="form-label">Email Address <span class="text-danger">*</span></label>
          <div v-if="data.email.error">{{data.email.message}}</div>
          <input type="email" class="form-control" v-model="data.email.input" maxlength="100">
        </div>
        
        <div class="col-md-12">
          <div :class="{'alert alert-danger': data.authorization.error}">
            <div v-if="data.authorization.error">This field is required</div>
            <input class="form-check-input" type="checkbox" v-model="data.authorization.input">
            <label class="form-check-label" for="gridCheck">&nbsp;
              I am an authorized user of this email address and I want to receive emails from Ma Labs Sales Department.
            </label>
          </div>
        </div>
        <div class="col-12">
            <button v-if="waiting" class="btn btn-primary btn" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Submit
              </button>
            <button v-else type="submit" class="btn btn-primary btn" @click.prevent="handleSubmit">Submit</button>
          </div>
      </form>

      
  </div>

  <div id="google_recaptcha"></div>
</template>

<script>
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { ref, onMounted } from 'vue'
import $ from 'jquery'
import { insertReCaptchaScript, handleReCaptcha, checkEmail } from '../../utils/common'
import { getAPI } from '../../utils/axios-api'

export default {
  name: 'UnblockMe',
  components: { BreadcrumbPage },
  setup() {
    const waiting = ref(false)
    const error = ref(null)
    const message = ref(null)
    const data = ref({
      email: {input: null, error: false, message: ""},
      authorization: {input: true, error: false},
    })

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Home", link: "Home"})
    breadcrumb_links.value.push({text: "Unblock Me", link: ""})

    //add script for google reCaptcha to template
    onMounted(() => {
        insertReCaptchaScript("google_recaptcha")
    })

    function validate() {
      let valid = true

      if (data.value.email.input != "" && data.value.email.input != null) {
        if (checkEmail(data.value.email.input) == true)
          data.value.email.error = false
        else {
          valid = false 
          data.value.email.error = true
          data.value.email.message = "The email address is invalid."
        }
      }
      else {
        valid = false 
        data.value.email.error = true
        data.value.email.message = "The email address is required."
      }

      if (data.value.authorization.input == true) {
        data.value.authorization.error = false
      }
      else {
        valid = false 
        data.value.authorization.error = true
      }

      return valid
    }

    const handleSubmit = async () => {
      waiting.value = true
      error.value = null
      message.value = null

      let result = await handleReCaptcha(grecaptcha)
      if (result["is_human"] == true) {
        //validate the form
        if (validate() == false) {
          waiting.value = false
          return 0
        }
        
        let payload = {
          email: data.value.email.input,
        }
        
        getAPI.post('/account/blockme/register/unblock/', payload)
        .then((response) => {
          let res = response.data

          message.value = res["message"]
          
          waiting.value = false
        })
        .catch(err => {
          error.value = err.response.data.message
          waiting.value = false
        })
      }
      else {
        //if not pass google reCaptcha, show the error
        waiting.value = false
        error.value = result["message"]
      }

    }


    return {
      waiting,
      error,
      message,
      data,
      handleSubmit,
      breadcrumb_links
    }

  }
}
</script>

<style scoped>
.lead {
  font-size: 26px;
  font-family: "Roboto";
  font-weight: 600;
  color: #555555;
}
</style>